import React, { useEffect } from "react";

import { CookiesProvider, useCookies } from 'react-cookie';

import WarehouseApp from './WarehouseApp'
import { SignIn } from './Setup'

import { Avatar, Box, Button, Container, createTheme, CssBaseline, ThemeProvider, Typography } from "@mui/material";

import './App.css'

const App = () => {

  return (
    <CookiesProvider>
      <Environment />
    </CookiesProvider>
  );
}

export default App;



const Environment = () => {

  const [cookies] = useCookies(['secret']);

  const posConfig = {
    type: 'monitor',
    connection: {
      station: null,
    }
  }

  const isLoggedIn = !! cookies.secret

  const CustomFontTheme = createTheme({
    typography: {
      fontSize: 16
    }
  });
  
  return (
    <ThemeProvider theme={CustomFontTheme}>
      <Container component="main" sx={{width:'100%'}} maxWidth={false}>
        <CssBaseline />
        { isLoggedIn && <WarehouseApp posConfig={posConfig} /> }
        { ! isLoggedIn && <SignIn /> }    
      </Container>
    </ThemeProvider>
  )
}




