import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import uuid from 'react-uuid';
import QRCode from 'react-qr-code';
import { Alert } from '@mui/material';


const CHECKOUT_APP_BASE = 'http://checkout.pos.mpsapi.de/'



const SignIn = () => {

  const [cookies, setCookie] = useCookies(['secret']);
  const [showQr, setShowQr] = useState(false)
  const [newSecret, setNewSecret] = useState(false)
  
  const handleDisplayCode = () => {
    setNewSecret('XMPSA' + uuid()+uuid()+uuid()+uuid())
    setShowQr(true)
  }

  const handleSetSecret = () => {
    const expires = new Date(Date.now()+60 * 60 * 24 * 366 * 1000)
    setCookie('secret', newSecret, { path: '/', expires })
  }

  return (
    <Card sx={{textAlign:'center', mt: 2}}>
      
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Gerät registrieren
        </Typography>
        <Container>
          <Box sx={{ mt: 4, p:2 }}>
            { ( showQr && newSecret)
              ? (<>
                <a target="_blank" href={CHECKOUT_APP_BASE+'admin/authorize/' + newSecret}><QRCode style={{margin: '16px'}} value={CHECKOUT_APP_BASE + 'admin/authorize/' + newSecret}/></a>
                <Alert severity='warning'>Als erstes den Registrierungscode scannen, dieser wird nur jetzt angezeigt!<br/>Der Code muss mit einem <b>Admin-Handy</b> gescannt werden.</Alert>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 2 }}
                  onClick={handleSetSecret}
                  color="success"
                  >
                  Registrierungscode wurde gescannt
                </Button>
              </>
              ) 
              : (
                <Box noValidate sx={{ mt: 1 }}>
                  <Button
                    fullWidth
                    color="success"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleDisplayCode}
                    >
                    Registrierungscode anzeigen
                  </Button>
                </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Card>
  );
}




const LinkSetup = () => {

  const [cookies, setCookie] = useCookies(['station']);

//  const { link } = useParams()
  const link = 'RAND'


  useEffect(()=>{
    if(link){
      const expires = new Date(Date.now()+60 * 60 * 24 * 366 * 1000)
      setCookie('station', link, { path: '/', expires })
    }
  }, [link])

  
  if( ! link) return <>
    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5" sx={{mb:4}}>
      Checkout verbinden
    </Typography>
    <Typography gutterBottom paragraph>Mit diesem Gerät den QR-Code-Link der zu verbindenden Checkout-Station scannen.</Typography>
    <Typography gutterBottom paragraph>Hierfür auf der zu verbindenden Checkout-Station auf /setup navigieren.</Typography>
    <RemoveDevice />
  </>

  return <>
  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5" sx={{mb:4}}>
      Checkout verbinden
    </Typography>
    <Typography gutterBottom paragraph>Das Gerät ist erfolgreich verbunden.</Typography>
    
  </>
}

export {SignIn, LinkSetup}


function RemoveDevice(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['station', 'secret']);

  const handlerRemoveDevice = () => {
    removeCookie('station')
    removeCookie('secret')
  }

  return (
    <Box>
      <Button sx={{mt:4}} variant="outlined" color="error" onClick={handlerRemoveDevice}>System logout</Button>
    </Box>
  );
}