import {
  Alert,
  Box, Card, Container, Divider, Grid, Typography,
} from '@mui/material'
import { useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useCookies } from 'react-cookie';

import dhlLogo from './speditionen/dhl.png'
import hermesLogo from './speditionen/hermes.png'
import dpdLogo from './speditionen/dpd.png'
import upsLogo from './speditionen/ups.png'



const socketUrl ='wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';



const WarehouseApp = ({posConfig}) => {


  const [cookies] = useCookies(['secret', 'station', 'staff'])

  const { sendMessage, readyState } = useWebSocket(socketUrl, 
    {
      queryParams: {
        station: posConfig.connection.station,
        secret: cookies.secret,
        type: 'monitor',
        staff: cookies.staff
      },
      onMessage: (message) => handleWebsocketEvent(message),
      shouldReconnect: (_closeEvent) => true,
      retryOnError: true,
      onClose: () => console.log('closing'),
      onError: (err) => console.log('error', err),
      onReconnectStop: (err) => console.log('recon stop', err),
    }
  );



  const [stations, setStations] = useState([])

  const handleWebsocketEvent = (lastMessage) => {

    const msg = JSON.parse(lastMessage.data)
    console.log(msg)
    if(msg.task==='pickupMonitorMessageResponse'){
      const {station, stationName, pickupList, unclutterList, customerName, customerId} = msg
      const same = stations.filter(e=>e.id===station)
      //const isNewStation = same.length === 0
      const remaining = stations.filter(e=>e.id!==station)
    
      setStations([
        ...remaining,
        {
          id: station,
          stationName,
          pickupList,
          unclutterList,
          customerName,
          customerId
        }
      ])
    
    }
  }

  

  return (
    <Container maxWidth={false} sx={{mt: 4}}>
      <Grid container spacing={4}>
        {stations.filter(e=>e.pickupList.length||e.unclutterList).sort((a,b)=>a.stationName.localeCompare(b.stationName)).map((e,i)=>{

          const groupedPickupList = e.pickupList.reduce((p,c)=>{
            const matching = p.filter(e=>e.group === c.lager_name)
            const remaining = p.filter(e=>e.group !== c.lager_name)
            if( matching.length === 0){
              matching.push({
                group: c.lager_name,
                shipments: [c]
              })
            }else{
              matching[0].shipments.push(c)
            }
            p = [
              ...matching,
              ...remaining
            ]
            return p
          },[])

          return (
            

            <Grid key={i} item xs={4}>
              <Typography color="white" variant="h4">{e.stationName}</Typography>
              <Card sx={{backgroundColor: 'lightblue'}}>
                {e?.unclutterList && <Box p={2}><Alert severity='info'>Umlagerung</Alert></Box>}
                <Card sx={{p:2}}>
                  <Divider />
                  <Typography fontFamily="Courier">{e.customerName}</Typography>
                  <Typography fontSize={28} fontWeight={700} fontFamily="Courier">{e.customerId}</Typography>
                  <Divider />
                    {groupedPickupList.map((ee,i)=>{
                      return (
                        <Box key={i}>
                          <Typography variant="h4">{ee.shipments[0].lager_name}</Typography> 
                          <Box>
                            {ee.shipments.map((shipment, i)=>{
                              return (
                                <Box key={i}>
                                  <Typography>
                                    {shipment.id}&nbsp;&nbsp;&nbsp;<b>{shipment.klasse}</b>&nbsp;&nbsp;&nbsp;<Spedition spedition={shipment.spedition} /> {shipment?.absender?.slice(0, 28)}
                                  </Typography>
                                </Box>
                              )
                            })}
                          </Box>
                        </Box>
                      )
                    })}

                    {e?.unclutterList?.storageSlots?.map((ee, ii) => {
                      return (
                        <Card key={ii} sx={{ p: 2, my: 1 }}>
                          <Typography variant="h4">{ee.storageName}</Typography>
                          {ee.shipments.map((shipment,iii)=>{
                            return (
                              <Box key={iii}>
                                <Typography>
                                  {shipment.mpsid}&nbsp;&nbsp;&nbsp;<b>{shipment.klasse}</b>
                                </Typography>
                              </Box>
                            );
                          })}
                          {ee?.shipments?.length>2 && <Typography variant="caption" gutterBottom>{ee?.shipments?.length} Sendungen</Typography>}
                        </Card>
                      );
                    })}
                  
                </Card>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Container>
  );
}

export default WarehouseApp;




const Spedition = ({spedition}) => {

  const imageComponents = {
    'DHL': <Box component="img" sx={{height:'20px'}} alt={spedition} src={dhlLogo} />,
    'Hermes': <Box component="img" sx={{height:'20px'}} alt={spedition} src={hermesLogo} />,
    'DPD': <Box component="img" sx={{height:'20px'}} alt={spedition} src={dpdLogo} />,
    'UPS': <Box component="img" sx={{height:'20px'}} alt={spedition} src={upsLogo} />
  }

  const value = spedition in imageComponents ? imageComponents[spedition] : '[' +spedition+ ']'

  return (
    <Box component="span" sx={{display:'inline-block', minWidth:'42px'}}>{value}</Box>
  )
}